<template>
    <div class="test">
        <video-record />
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import VideoRecord from '../../components/VideoRecord.vue'

export default {
    components: { VideoRecord },
    mounted() {
        if (!this.user) {
            this.$navigate('/login')
        }
    },
    computed: {
        ...mapGetters(['user'])
    }
}
</script>

<style lang="scss" scoped>
.text {
    position: relative;
    overflow: hidden;
}
</style>

