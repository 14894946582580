// https://www.webrtc-experiment.com/msr/video-recorder.html

import Events from './events'

class VideoRecorder extends Events {

    constraintObj = {}
    canRecord = false
    mediaRecorder = null
    blob = null
    chucks = []

    createdObject = null

    constructor(video, faceToUser = false) {
        super()
        this.initialize(video, faceToUser)
    }

    async initialize(video, faceToUser = false) {
        this.canRecord = false
        if (navigator && navigator.mediaDevices) {
            let devices = await navigator.mediaDevices.enumerateDevices()
            devices.forEach(device=>{
                //console.log(device.kind.toUpperCase(), device.label);
                //, device.deviceId
                this.canRecord = true
            })
        }

        if (!this.canRecord) return

        this.constraintObj = { 
            audio: true, 
            video: { 
                facingMode: faceToUser ? "user" : "environment", 

                height: 720, width: 720

                //width: { min: 720, ideal: 720, max: 720 },
                //height: { min: 720, ideal: 720, max: 720 } 

                //height: { min: 480, ideal: 720, max: 720 },
                //width: { min: 640, ideal: 1280, max: 1280 } 

                //height: 480, width: 640
                //height: 360, width: 640
                //height: 240, width: 426
            } 
        }

        let mediaStreamObj = await navigator.mediaDevices.getUserMedia(this.constraintObj)
        /*********************************
        getUserMedia returns a Promise
        resolve - returns a MediaStream Object
        reject returns one of the following errors
        AbortError - generic unknown cause
        NotAllowedError (SecurityError) - user rejected permissions
        NotFoundError - missing media track
        NotReadableError - user permissions given but hardware/OS error
        OverconstrainedError - constraint video settings preventing
        TypeError - audio: false, video: false
        *********************************/
        
        if ("srcObject" in video) {
            video.srcObject = mediaStreamObj
        } else {
            //old version
            video.src = window.URL.createObjectURL(mediaStreamObj)
        }

        video.onloadedmetadata = function(ev) {
            //show in the video element what is being captured by the webcam
            video.play();
        }

        //this.mediaRecorder = new MediaStreamRecorder(mediaStreamObj)
        
        this.mediaRecorder = new MediaRecorder(mediaStreamObj, {
            //mimeType: 'video/webm'
        });
        
        //this.mediaRecorder.mimeType = 'video/webm';
        //this.mediaRecorder.mimeType = 'video/mp4';
        this.mediaRecorder.ondataavailable  = (e) => {
            this.blob = e.data
            this.chucks.push(e.data)
            this.emit('stop', this.chucks)
        }

        console.log('passou em tudo')
    }

    savedURL() {
        if (this.createdObject != null) 
        {
            window.URL.revokeObjectURL(this.createdObject)
            delete this.createdObject
            this.createdObject = null
        }

        this.createdObject = this.blob ? window.URL.createObjectURL(this.blob) : null
        return this.createdObject
    }

    reset() {
        delete this.blob
        this.blob = null

        delete this.chucks
        this.chucks = []

        if (this.createdObject != null) 
        {
            window.URL.revokeObjectURL(this.createdObject)
            delete this.createdObject
            this.createdObject = null
        }
    }

    async start() {
        console.log('start record')
        this.blob = null
        this.chucks = []
        if (this.mediaRecorder) this.mediaRecorder.start()
    }
    
    async stop() {
        console.log('stop record')
        if (this.mediaRecorder) {
            console.log(this.mediaRecorder)
            this.mediaRecorder.stop()
        }
    }
}

export default VideoRecorder