import Vue from 'vue'
import router from '@/scripts/router'
import store from '@/scripts/store'

class Utils {

    navigate(path) {
        
        path = path.replace('{{clientID}}', 1)
        console.log('path', path)

        if (path.indexOf('/client') == 0) {
            store.commit('client', 1)
        }
        else if (path.indexOf('/') == 0) {
            store.commit('client', null)
        }

        router.push(path).catch(() => {})
    }

}

Vue.prototype.$utils = new Utils()
