import Vue from 'vue'
import rest from './rest.js'
import {sessionInit, sessionTerminate} from './session.js'

class API {

    // AUTH ----------------------------

    async signin(email, password) {
        let res = await rest.post('/auth', {email, password})
        console.log('res', res)
        if (res.data) { sessionInit(res.data) }
        return res
    }

    async signout() {
        sessionTerminate()
    }

    // JOBS ----------------------------

    async getUserJobs() {
        return await rest.get('/job')
    }

    async getJob(jobID) {
        console.log('getJob', jobID)
        return await rest.get('/job/' + jobID)
    }

    async getJobCheckList(jobID) {
        return await rest.get(`/job/${jobID}/checklist`)
    }

    async saveJobChecklist(jobID, checklist) {
        return await rest.put(`/job/${jobID}/checklist`, checklist)
    }

    // FILES ---------------------------

    async getFile(fileID) {
        return await rest.get('/file/' + fileID)
    }

    async uploadVideo(file, description, sender) {
        let res = await rest.upload(file, '.webm')
        if (!res || !res.file) return null
        let saveData = {key:res.file, description, sender}
        res = await rest.post('/file/save', saveData)
        console.log(res)
    }
}

Vue.prototype.$api = new API()
