import router from '@/scripts/router'
import store from '@/scripts/store'

const SESSION_NEED_AUTH = true
const SESSION_DATA = 'sessionTime'
const SESSION_LIMIT_TIME = 1000 * 60 * 60 * 12 // 12h

function storeUser() {
    let session = localStorage.getItem(SESSION_DATA)
    if (!session) return store.commit('user', null)
    let json = null
    try {
        json = JSON.parse(session)
    } catch {}
    if (!json || !json.user) 
    {
        store.commit('user', null)
        return
    }
    store.commit('user', json.user)
}

export const sessionTimedOut = () => {
    let session = localStorage.getItem(SESSION_DATA)
    let loginTime = 0
	if (session) { loginTime = JSON.parse(session).iat }
	loginTime = new Date().getTime() - loginTime
	return (loginTime > SESSION_LIMIT_TIME)
}

export const sessionRefresh = () => {
    let session = localStorage.getItem(SESSION_DATA)
    if (!session) return
    else session = JSON.parse(session)
	session.iat = new Date().getTime()
    localStorage.setItem(SESSION_DATA, JSON.stringify(session))
    storeUser()
}

export const sessionTerminate = (redirect = true) => {
    localStorage.removeItem(SESSION_DATA)
    storeUser()
    if (redirect) {
        setTimeout(() => {
            if (router) router.push(`/login`).catch(() => {})
        }, 100)
    }
}

export const sessionInit = (user) => {
    let session = {}
    session.iat = new Date().getTime()
    session.user = user
    localStorage.setItem(SESSION_DATA, JSON.stringify(session))
    storeUser()
}

export const sessionCheck = () => {
    let session = localStorage.getItem(SESSION_DATA)
    if (!session) return false;
    else if (sessionTimedOut()) {
        sessionTerminate(true)
        return false;
    }

    storeUser()
    return true
}

export const authCheck = (to, from, next) => {

    if (!SESSION_NEED_AUTH) return true
    let goNext = true
    if (to.meta && to.meta.needAuth == true)
    {
        goNext = sessionCheck()
        if (goNext) sessionRefresh()
    }
    return goNext
}

setInterval(() => {
	sessionCheck()
}, 1000 * 10)
sessionCheck()
