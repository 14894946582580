<template>
    <div class="appmenu" @click="onClickOut" :style="blockWhenActive">
        <div class="appmenu-background" v-show="menu" :style="blockWhenActive"></div>
        <div class="menu" :style="appMenuStyle" @click="onClickMenu">
            <div class="btn-close">
                <v-icon large color="black" @click="onClickClose">mdi-close</v-icon>
            </div>

            <!-- 
            <div>
                <div v-for="(client, clientIndex) in clientList" :key="client.id" class="client">
                    <div class="client-name">{{ client.name }}</div>
                    <div v-for="(unit, unitIndex) in client.units" :key="unit.id">
                        <div class="unit-name">{{ unit.name }}</div>
                    </div>
                </div>
            </div>
            -->

            <v-spacer/>
            <p @click="onClickLogout"><v-icon>mdi-logout</v-icon> SAIR DA CONTA</p>
        </div>
    </div>    
</template>

<script>

import { mapGetters } from 'vuex'

export default {

    computed: {
        ...mapGetters(['menu']),

        appMenuStyle() {
            return {
                left: this.menu ? '0px' : '-100%'
            }
        },

        blockWhenActive() {
            return {
                "pointer-events": this.menu ? 'unset' : 'none'
            }
        },

        clientList() {
            let arr = []
            arr.push({
                name: "Cliente 1",
                id: 1456,
                units: [
                    {
                        name: "Unidade 1",
                        id: 1848
                    },
                    {
                        name: "Unidade 2",
                        id: 2984
                    }
                ]
            })
            arr.push({
                name: "Cliente 2",
                id: 2156841,
                units: [
                    {
                        name: "Unidade 1",
                        id: 18552
                    },
                    {
                        name: "Unidade 2",
                        id: 211104
                    }
                ]
            })
            return arr
        }
    },

    methods: {
        onClickMenu(e) {
            e.stopPropagation()
            console.log("click in")
        },

        onClickOut() {
            this.$store.commit("menu", null)
            console.log("click out")
        },

        onClickClose() {
            this.$store.commit("menu", null)
        },

        onClickLogout() {
            this.$navigate('/login')
        }
    }
    
}
</script>

<style lang="scss" scoped>

.appmenu {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;

    opacity: 1;

    .appmenu-background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
    }

    .menu {
        position: absolute;
        height: 100%;
        width: calc(100% - 6rem);
        background-color: white;
        padding: 1.5rem;

        display: flex;
        flex-direction: column;

        transition: all 0.25s ease-in-out;
    }

    .btn-close {
        position: relative;
        left: -0.6rem;
        top: 0.4rem;
        height: 3rem;

        i {
            color: black !important;
            transition: none !important;
        }
    }
}

</style>
