/*
var s;
var password = "12345678";

while(true){
    if(s != password){
        s = prompt("Please enter password!");
    } else {
        break;
    }
}
*/

import debug from './scripts/debug'
debug(process.env.NODE_ENV !== 'production')

import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from '@/scripts/router'
import store from '@/scripts/store'

import '@/scripts/api.js'
import '@/scripts/utils.js'
import '@/scripts/global-components.js'

import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import vuetify from './plugins/vuetify'
import pack from '../package.json'

import env from './scripts/env.js'

Vue.config.productionTip = false
Vue.prototype.$package = pack

Vue.prototype.$wait = ms => new Promise(res => setTimeout(res, ms))
Vue.prototype.$navigate = path => {

  if (path == -1) {
    router.back()
    return
  }
  
  router.push(path).catch(x => {})
}
Vue.prototype.$env = env

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

/*
// register service worker
if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('./sw.js')
    .then(reg => {
      console.log('Registrando nosso primeiro service worker', reg)
    })
    .catch(err => {
      console.log('Algo de errado aconteceu', err)
    })
  })
}
*/