<template>
    <div class="envtag" v-if="visible">
        <p>{{envName}}</p>
    </div>
</template>

<script>
export default {
    computed: {
        envName() {
            return process.env.NODE_ENV
        },

        visible() {
            return this.envName != "production"
        }
    }
}
</script>

<style lang="scss" scoped>
.envtag {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: yellow;
    border-radius: 0 0 0.5rem 0.5rem;
    pointer-events: none;

    p {
        margin: 0.2rem 0.5rem;
        padding: 0;
        font-size: 0.8rem;
    }

}
</style>