<template>
    <v-main align="center">
        <v-window touchless v-model="page">
            <v-window-item>
                <v-col class="form-login mt-12">
                    <div class="logo">
                        <img src="assets/images/logo.png">
                    </div>
                    <div class="welcome">
                        <h1>Bem vindo<br>de volta</h1>
                        <p>Entre com suas credenciais para continuar</p>
                    </div>
                    <v-text-field autocomplete="off" filled label="Usuário" v-model="email" />
                    <v-text-field autocomplete="off" filled label="Senha" type="password" v-model="password" />
                    <v-container class="errormessage">
                        <p v-if="error" v-html="error" class="error--text"></p>
                        <!-- <a v-if="error" @click="onClickForgotPassword">Esqueci minha senha</a> -->
                    </v-container>
                    <v-spacer />
                    <v-btn :loading="loading" color="primary" x-large class="btnsubmit" @click="onClickSubmit">Entrar</v-btn>
                </v-col>
            </v-window-item>

            <v-window-item>
                <v-col class="form-login mt-12">
                    <v-text-field label="E-mail" v-model="email" persistent-hint :hint="error || ''" />
                    <v-col>
                        <v-btn :loading="loading" color="primary" class="btnsubmit" @click="onClickSubmit">Enviar</v-btn>
                        <v-container class="mt-4">
                            <a @click="page = 0">Voltar</a>
                        </v-container>
                    </v-col>
                </v-col>
            </v-window-item>
        </v-window>
    </v-main>
</template>

<script>

import { sessionTerminate } from '../../scripts/session'

export default {

    data: () => ({
        email: "", password: "",
        error: "",
        loading: false,
        page: 0
    }),

    mounted() {
        sessionTerminate()
        this.$store.commit('menu', false)
        window.fill = async () => {
            this.email = 'leandro@trampasampa.com.br'
            this.password = '123'
            await this.$wait(500)
            this.onClickSubmit()
        }
    },

    beforeDestroy() {
        window.fill = null
    },

    methods: {

        onClickForgotPassword() {
            console.log('forgot')
            this.page = 1
            this.error = 0
        },

        onClickSignup() {
            console.log('signup')
            this.$navigate('/policy')
        },

        async onClickSubmit() {
            this.error = null
            this.loading = true
            await this.$wait(100)

            let email = this.email.trim()
            let password = this.password.trim()

            console.log('enter', {email, password})
            
            let res = await this.$api.signin(email, password)
            this.loading = false

            this.error = (!res || res.error) ? 'Dados inválidos' : null
            if (!this.error) {
                this.$navigate('/')
            }
        }
    }
}
</script>

<style lang="scss" scoped>

.welcome {
    text-align: left;
    h1 {
        font-size: 3.4rem;
        line-height: 1.1em;

        @media screen and (max-width: 400px) {
            font-size: 2.4rem;
        }
    }

    h1, p {
        margin-bottom: 2rem;

        @media screen and (max-width: 400px) {
            margin-bottom: 1rem;
        }
    }
}

.logo {
    
    text-align: left;

    img {
        height: 48px;
        max-width: calc(100% - 4rem);
    }

    @media screen and (max-width: 400px) {

        img {
            height: 32px;
        }
    }
}

.btnsubmit {
    margin-bottom: 2rem;
}

</style>