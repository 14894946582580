<template>
    <div ref="form" :class="{'form session scroll':true}">
        <div :class="{'form-data':true, 'session-animation':m_animate}" v-if="checklist != null">
            <div v-for="(q, questionIndex) in checklist.items" :key="jobID + '_question-' + questionIndex" class="question-item" :ref="`question-${questionIndex + 1}`">
                <!-- <div v-if="question == questionIndex"> -->
                    <p class="region" v-if="q.showRegion">{{q.region}}</p>
                    <p class="question">{{questionIndex + 1}} - {{q.question}}</p>

                    <div v-if="q.type=='options'">
                        <div class="radio" v-for="(item, index) in q.options" :key="index"  @click="setAnswer(questionIndex, index)">
                            <div class="check">
                                <div class="selected" v-if="index == checklist.items[questionIndex].value"></div>
                            </div>
                            <p>{{item}}</p>
                        </div>
                    </div>

                    <div v-else-if="q.type=='yesno'">
                        <div class="radio" v-for="(item, index) in ['Sim', 'Não']" :key="index"  @click="setAnswer(questionIndex, item)">
                            <div class="check">
                                <div class="selected" v-if="item == checklist.items[questionIndex].value"></div>
                            </div>
                            <p>{{item}}</p>
                        </div>
                    </div>

                    

                    <div v-else-if="q.type=='number'">
                        <!-- <input type="file" accept="image/*" capture="camera" /> -->
                        <v-text-field type="number" filled v-model="checklist.items[questionIndex].value" />
                    </div>

                    <div v-else-if="q.type=='textarea'">
                        <!-- <input type="file" accept="image/*" capture="camera" /> -->
                        <v-textarea filled v-model="checklist.items[questionIndex].value"></v-textarea>
                    </div>

                    <div v-else>
                        <!-- <input type="file" accept="image/*" capture="camera" /> -->
                        <v-text-field filled v-model="checklist.items[questionIndex].value" />
                    </div>

                <!-- </div> -->
            </div>
        </div>
        <div class="d-flex justify-center">
            <a class="question-error" v-show="notAnsweredQuestion != null" @click="scrollMeTo(`question-${notAnsweredQuestion}`)"><v-icon>mdi-alert</v-icon> Falta responde o item {{notAnsweredQuestion}}</a>
        </div>
        <div class="buttons" v-if="checklist != null">
            <!--
            <v-btn large @click="onClickPrev" :disabled="!checklist || question == 0">Voltar</v-btn>
            <v-btn large color="primary" class="next" @click="onClickNext" :disabled="!checklist || this.checklist.items[question].value == null">Avançar</v-btn>
            -->
            <v-btn large color="primary" class="next" @click="onClickNext" :disabled="notAnsweredQuestion">Enviar</v-btn>

        </div>

        
        <div class="sending-modal" v-if="sendingStep >= 0">

            <div class="frame" v-if="sendingStep == 0">
                <h1>Enviando</h1>
                <v-icon class="large-icon rotateLoop">mdi-sync</v-icon>
                <div class="space"></div>
                <p>Estamos salvando todos os dados em nosso servidor, por favor aguarde...</p>
                <div class="space"></div>
            </div>

            <div class="frame" v-else-if="sendingStep == 1">
                <h1>Sucesso</h1>
                <v-icon class="large-icon" color="primary">mdi-checkbox-marked-circle-outline</v-icon>
                <h2>Obrigado por colaborar</h2>
                <p>Esse Trampo foi adicionado a sua lista de Trampos realizados.</p>
                <div class="space"></div>
                <v-btn large color="primary" @click="$navigate('/')">Concluir</v-btn>
            </div>

            <div class="frame" v-else-if="sendingStep == 2">
                <h1>Erro!</h1>
                <v-icon class="large-icon" color="error">mdi-alert-circle-outline</v-icon>
                <h2>Falha ao enviar dados</h2>
                <p>Por favor tente novamente</p>
                <div class="space"></div>
                <v-btn large color="error" @click="sendForm">Tentar Novamente</v-btn>
                <a @click="backToHome" class="cancelSend">Cancelar</a>
            </div>
        </div>
    </div>
</template>

<script>
export default {

    props: ['jobID'],

    data: () => ({
        question: -1,
        checklist: null,

        m_animate: false,
        m_animateTimeout: null,

        sendingStep: -1
    }),

    beforeMount() {
        
    },

    async mounted() {

        const jobRes = await this.$api.getJob(this.jobID)
        this.$store.commit('job', jobRes.data)

        let checklistRes = await this.$api.getJobCheckList(this.jobID)
        console.log("CHECKLIST RES", checklistRes)
        this.checklist = checklistRes.data.checklist
        this.question = 0

        let lastRegion = null
        for (let i = 0; i < this.checklist.items.length; i++) {
            if (this.checklist.items[i].region != lastRegion) {
                lastRegion = this.checklist.items[i].region
                this.checklist.items[i].showRegion = true
            } else {
                this.checklist.items[i].showRegion = false
            }
        }

        this.move(0)
    },

    computed: {
        notAnsweredQuestion() {
            if (!this.checklist) return null
            for (let i = 0; i < this.checklist.items.length; i++) {
                if (!this.checklist.items[i].value) return i + 1
            }
            return null
        }
    },

    methods: {

        scrollMeTo(refName) {
            console.log("scrollMeTo", refName)
            let element = this.$refs[refName][0];
            console.log('element', element)
            let top = element.offsetTop;
            console.log('top', top - this.$refs.form.offsetTop)
            this.$refs.form.scrollTo(0, top - this.$refs.form.offsetTop);
        },

        move(amount) {
            this.m_animate = false;
            clearTimeout(this.m_animateTimeout)
            this.m_animateTimeout = setTimeout(() => {
                this.question += amount
                this.m_animate = true
            }, 1)
        },

        setAnswer(index, value) {
            console.log('setAnswer', index, value)
            this.checklist.items[index].value = value
            this.checklist = Object.assign({},  this.checklist)
        },

        async onClickNext() {
            /*
            if (this.question < this.checklist.items.length - 1) 
            {
                this.move(1)
            }
            else 
            {
                this.sendForm()
            }
            */
           this.sendForm()
        },

        backToHome() {
            this.$navigate('/')
        },

        async wait(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },

        getElapsedTimeSince(time) {
            let now = new Date().getTime()
            let elapsed = now - time
            return elapsed
        },

        async sendForm() {

            let sendData = JSON.parse(JSON.stringify(this.checklist))
            for (let i = 0; i < sendData.items.length; i++) {
                delete sendData.items[i].showRegion
            }

            this.sendingStep = 0
            const start = +new Date
            let res = await this.$api.saveJobChecklist(this.jobID, sendData)
            while (this.getElapsedTimeSince(start) < 1000) {
                await this.wait(100)
            }
            this.sendingStep = (!res || res.error) ? 2 : 1
        },

        onClickPrev() {
            this.move(-1)
        }
    }
}
</script>

<style lang="scss" scoped>


.sending-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(128, 128, 128, 0.25);

    display: flex;
    flex-direction: column;
    overflow: hidden;

    .frame {
        position: relative;
        transform: translate(-50%);
        text-align: center;
        left: 50%;
        margin-top: 2rem;
        margin-bottom: 0;
        max-width: calc(500px - 4rem);
        padding: 1rem;
        padding-top: 3rem;
        padding-bottom: 3rem;
        flex-grow: 1;
        background-color: white;
        border-radius: 1rem 1rem 0 0;
        box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
        display: flex;
        flex-direction: column;

        .space {
            flex-grow: 1;
        }

        .large-icon {
            font-size: 8rem;
            margin: 1rem;
        }

        h2 {
            margin-bottom: 1rem;
        }
    }

    @keyframes rotateAnimation
    {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

    .rotateLoop {
        animation: rotateAnimation 2s linear infinite reverse;
    }

    h2 {
        font-size: 1rem;
    }

    p {
        font-size: 0.9rem;
    }

    .cancelSend {
        margin-top: 1rem;
        color: #666;
    }

}

.error-modal {

}

.form {
    display: flex;
    flex-direction: column;
    color: #333;
}

.form-data {
    flex-grow: 1;
}

.buttons {
    display: flex;

    .next {
        margin-left: 1rem;
        flex-grow: 1;
    }
}

.question {
    font-size: 1.3rem;
    font-weight: 300;
}

.radio {
    display: flex;
    margin: 0.5rem 0;
    padding: 0.5rem 0;
    align-items: center;

    p {
        margin: 0;
        padding: 0;
        margin-left: 1rem;
        font-size: 1.2rem;
    }

    .check {
        position: relative;
        width:  1.25rem;
        height: 1.25rem;
        border-radius: 50%;
        border: 1px solid #333;
    }

    .selected {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width:  10px;
        height: 10px;
        border-radius: 50%;
        background-color: #333;
    }
}

.question-item {
    margin-bottom: 2rem;
}

.region {
    font-size: 1.5rem;
}

.question-error {
    color: red;
    margin-bottom: 2rem;
}

</style>