<template>
    <div class="approot">
        <appheader></appheader>
        <router-view />
        <appmenu></appmenu>
    </div>
</template>

<script>
export default {
    
}
</script>

<style lang="scss">

.approot {
    background-color: white;
    overflow: hidden;

    display: flex;
    flex-direction: column;
}

/* The animation code */
@keyframes sessionEnter {
  0%   {opacity: 0; margin-top: 1.5rem;}
  100% {opacity: 1; margin-top: unset;}
}

.session-animation {
    animation-name: sessionEnter;
    animation-duration: 0.5s;
}

.session {
    padding: 0 1.5rem 3rem 1.5rem;
}

.scroll {
    flex-grow: 1;
    overflow-y: auto;
}

</style>
