<template>
    <div class="session">
        <p v-if="job" class="jobdescription" v-html="job.checklist.description"></p>
        <v-btn large color="primary" style="width: 100%" @click="$navigate(`/job/${id}/checklist`)">Preencher Formulário</v-btn>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {

    props: ['id'],

    data: () => ({
    }),

    async mounted() {
        const jobRes = await this.$api.getJob(this.id)
        console.log('jobRes', jobRes)
        this.$store.commit('job', jobRes.data)
    },

    methods: {
        
    },

    computed: {
        ...mapGetters({
            job: 'job'
        })
    }
}
</script>

<style lang="scss" scoped>
    p {
        margin-top: 1rem;
        margin-bottom: 3rem;
        font-size: 1.4rem;
        font-weight: 300;
    }
</style>