import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    client: null,
    user: null,
    job: null,
    menu: null
  },
  getters: {
    client: (state, globalState) => state.client,
    user: (state, globalState) => state.user,
    job: (state, globalState) => state.job,
    menu: (state, globalState) => state.menu,
  },
  mutations: {
    client: (state, value) => state.client = value,
    user: (state, value) => state.user = value,
    job: (state, value) => state.job = value,
    menu: (state, value) => state.menu = value,
  },
  actions: {
  },
  modules: {
  }
})
