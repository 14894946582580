<template>
    <div class="home">
        <div class="tabs">
            <div :class="{'tab':true, 'tab-selected':tab == 0}" @click="tab=0"><p>{{textTabNew}}</p></div>
            <div :class="{'tab':true, 'tab-selected':tab == 1}" @click="tab=1"><p>{{textTabDone}}</p></div>
            <!--
            <div v-if="showRevisedTab" :class="{'tab':true, 'tab-selected':tab == 2}" @click="tab=2"><p>{{textTabRevised}}</p></div>
            -->
        </div>
        <div class="filter" v-show="unitList.length > 1">
            <v-select label="Filtro por Unidade" dense filled :items="unitList" v-model="unit" item-text="name" item-value="id"></v-select>
        </div>
        <div class="scroll session" v-if="jobs && currentTabJobs.length > 0">
            <div v-for="(job, index) in currentTabJobs" :key="job.id" @click="onClickJob(job)">
                <div class="job" v-if="job.status == statusByTab" >
                    <div class="picture"></div>
                    <div v-if="job != null">
                        <p class="h1">{{job.room.name}}</p>
                        <p class="h2">{{job.unit.name}}</p>
                        <p class="h3">{{job.date}}</p>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="session nodata">
            <p class="message" v-html="noDataTextByTab"></p>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    data: () => ({
        tab: 0,
        jobs: null,
        unit: null,
        unitList: [],

        refreshInterval: null
    }),

    async mounted() {
        this.$store.commit('menu', false)
        this.$store.commit('job', null)

        this.load()
        this.refreshInterval = setInterval(() => this.load(), 5000)
    },

    beforeDestroy() {
        clearInterval(this.refreshInterval)
    },

    methods: {
        onClickJob(job) {
            if (job.status == "done") return
            this.$navigate('/job/' + job.id)
        },

        async load() {
            let res = await this.$api.getUserJobs()
            this.jobs = res.data
            this.unitList = res.data.map(job => {
                return {
                    id: job.unit.id,
                    name: job.unit.name
                }
            })
            .filter((unit, index, self) =>
                index === self.findIndex((t) => (
                    t.id === unit.id
                ))
            )

            if (this.unitList.length == 1) {
                this.unit = this.unitList[0].id
            }
            else {
                // insert in first position of unitList
                this.unitList.unshift({
                    id: null,
                    name: "Todas"
                })
            }
        }
    },

    computed: {

        ...mapGetters(['user']),

        isWorker() {
            return this.user.role == "worker"
        },

        statusByTab() {
            return ["new", "done", "revised"][this.tab]
        },

        textTabNew() {
            if (!this.jobs) return "-"
            let size = this.jobs.filter(x => x.status == "new").length
            if (size == 0) return "Nenhum novo"
            if (size > 0) return `${size} Novo${size > 1 ? 's' : ''}`
        },

        textTabDone() {
            if (!this.jobs) return "-"
            let size = this.jobs.filter(x => x.status == "done").length
            if (size == 0) return "Nenhum realizado"
            if (size > 0) return `${size} Realizado${size > 1 ? 's' : ''}`
        },

        textTabRevised() {
            if (!this.jobs) return "-"
            let size = this.jobs.filter(x => x.status == "revised").length
            if (size == 0) return "Nenhum revisado"
            if (size > 0) return `${size} Revisado${size > 1 ? 's' : ''}`
        },

        currentTabJobs() {
            return !this.jobs ? [] : this.jobs.filter(x => x.status == this.statusByTab && (x.unit.id == this.unit || this.unit == null))
        },

        noDataTextByTab() {
            return [
                "Nenhum novo trampo encontrado para os próximos dias.",
                "Ainda não existem trampos realizado essa semana"
            ][this.tab]
        }
    }
}
</script>

<style lang="scss" scoped>
.home {

    display: flex;
    flex-direction: column;
    overflow-y: hidden;
    flex-grow: 1;

    .filter {
        position: relative;
        margin: 0 1.5rem;
    }

    .tabs {
        display: flex;
        margin-bottom: 1rem;
        padding: 0 1.5rem;

        .tab {
            width: 100%;
            padding: 0.5rem;
            background-color: #ddd;

            p {
                font-size: 0.8rem;
                text-align: center;
                margin: 0;
                padding: 0;
            }
        }

        .tab-selected {
            background-color: #044bd1;
            color: white;
        }

        .tab:first-child {
            border-radius: 0.5rem 0 0 0.5rem;
        }

        .tab:last-child {
            border-radius: 0 0.5rem 0.5rem 0;
        }
    }

    

    .job-list {
        flex-grow: 1;
        overflow-y: auto;
    }

    .job {
        height: 64px;
        margin-bottom: 0.7rem;
        display: flex;
        align-items: center;

        text-transform: uppercase;

        p {
            padding: 0;
            margin: 0;
            line-height: 1.2;
        }

        .h1 {
            font-size: 1rem;
            font-weight: 600;
        }

        .h2 {
            font-size: 0.9rem;
        }

        .h3 {
            font-size: 0.8rem;
            opacity: 0.7;
        }

        .picture {
            height: 64px;
            width: 64px;
            border-radius: 0.5rem;
            border: 1px solid #ddd;
            margin-right: 1rem;

            background: url("/assets/images/services/room.png") no-repeat;
            background-size: cover;
            background-clip: border-box;
        }
    }
}

.home-content {
    display: flex;
    flex-direction: column;
}

.nodata {

    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .message {
        text-align: center;
        font-size: 0.9rem;
        opacity: 0.6;
        padding: 2rem;
        padding-bottom: 4rem;
    }
}

</style>