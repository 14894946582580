import Vue from 'vue';

import Debugger from '@/components/Debugger.vue'
Vue.component('debugger', Debugger)

import AppHeader from '@/components/AppHeader.vue'
Vue.component('appheader', AppHeader)

import AppMenu from '@/components/AppMenu.vue'
Vue.component('appmenu', AppMenu)
