<template>
    <div class="appheader">
        <div class="appheader-content">
            
            <button @click="onClickBack" class="btn-back" v-if="showBack">
                <v-icon>mdi-chevron-left</v-icon>
            </button>
            <button @click="onClickMenu" class="btn-hamburguer" v-else>
                <v-icon>mdi-menu</v-icon>
            </button>

            <div class="space"></div>

            <div class="details flex" v-if="showUser">
                <v-avatar class="pointer mr-4" color="white" size="48">
                    <v-icon v-if="!useravatar" size="32">mdi-account</v-icon>
                    <img v-else :src="pictureURL" :alt="user.name">
                </v-avatar>
                <div class="details-area">
                    <p class="h1">{{userName}}</p>
                    <p class="h2" v-if="user.cpf">{{document}}</p>
                    <p class="h3">{{role}}</p>
                </div>
            </div>

            <div class="details job-details" v-if="showJob">
                <p class="h1">{{jobDetails.room}}</p>
                <p class="h2">{{jobDetails.unit}}</p>
                <p class="h3">{{jobDetails.date}}</p>
            </div>
        </div>
        <div class="appheader-end">

            

        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {

    data: () => ({
        pictureLoaded: false,
        pictureURL: null
    }),

    computed: {
        ...mapGetters(['user', 'job']),

        userName() {
            return this.user.name
        },

        document() {
            return 'CPF: ' + this.formatCPF(this.user.cpf)
        },

        role() {
            if (this.user.role == 'worker') return 'Colaborador'
            if (this.user.role == 'supervisor') return 'Supervisor'
            if (this.user.role == 'manager') return 'Manager'
            return this.user.role
        },

        category() {
            return this.user.category
        },

        showUser() {
            return this.$route.name == 'home'
        },

        showJob() {
            return this.$route.name != 'home'
        },

        showBack() {
            return this.$route.name != 'home'
        },

        jobDetails() {
            if (!this.job) return {}
            console.log('job', this.job)
            return {
                room: this.job.room.name,
                unit: this.job.unit.name,
                date: this.job.date
            }
        },

        useravatar() {
            return this.user.picture
        }
    },

    async mounted() {
        console.log("USER", this.user)
        let res = await this.$api.getFile(this.user.picture)
        if (!res || res.error) return
        this.pictureURL = res.data.url
    },

    methods: {

        formatCPF(cpf) {
            if (!cpf) return ''
            let a = cpf.replace(/[^\d]/g, "");
            return a.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
        },

        onClickMenu() {
            console.log("onClickUser")
            this.$store.commit("menu", true)
        },

        onClickBack() {
            this.$navigate(-1)
        }
    }
}
</script>

<style lang="scss" scoped>
.appheader {
    background-color: #044bd1;
    color: white;

    button, i {
        transition: none !important;
    }

    .space {
        flex-grow: 1;
    }

    .btn-back {
        position: relative;
        left: -1rem;
        height: 3rem;
        i {
            font-size: 3rem;
            color: white;
        }
    }

    .btn-hamburguer {
        position: relative;
        left: -0.5rem;
        height: 3rem;
        i {
            font-size: 2rem;
            color: white;
        }
    }

    .appheader-content {
        padding: 1.5rem;
    }

    .appheader-end {
        margin-top: 0rem;
        background-color: white;
        height: 1.5rem;
        border-radius: 1rem 1rem 0 0;
    }

    .details {

        position: relative;
        height: 100px;

        padding: 1rem 0;
        p {
            padding: 0;
            margin: 0;
            line-height: 1.4;
        }

        .h1 {
            font-size: 1rem;
            font-weight: 600;
        }

        .h2 {
            font-size: 0.9rem;
        }

        .h3 {
            font-size: 0.8rem;
            opacity: 0.7;
        }
    }

    .job-details {
        text-transform: uppercase;
    }

    .flex {
        display: flex;
    }

    .picture-area {
        position: relative;

        width: 64px;
        height: 64px;
        border-radius: 50%;
        background-color: white;
        margin-right: 1rem;

        background: url("https://randomuser.me/api/portraits/women/19.jpg") no-repeat;
        background-size: cover;
        background-clip: border-box;
    }

    .hamburguer {
        position: absolute;
        top: -0.5rem;
        left: -0.25rem;
        font-size: 2rem;
    }
}
</style>