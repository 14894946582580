<template>
  <v-app id="app" :style="cssProps">
      <router-view class="page rootpage" />
      <div class="version"><p>v{{version}}</p></div>
      <debugger></debugger>
  </v-app>
</template>

<script>

export default {

	data: () => ({
		time: "00:00"
	}),

	mounted() {
		this.updateTime()
		setInterval(() => this.updateTime(), 1000)
	},

  computed: {
    cssProps () {
		var themeColors = {}
		Object.keys(this.$vuetify.theme.themes.light).forEach((color) => {
		  themeColors[`--v-${color}`] = this.$vuetify.theme.themes.light[color]
		})
		return themeColors
    },

    version() {
      return this.$package.version
    }
  },

  methods: {
    updateTime() {
		let cd = new Date();
        this.time = this.zeroPadding(cd.getHours(), 2) + ':' + this.zeroPadding(cd.getMinutes(), 2);
	},
	
    zeroPadding(num, digit) {
        let zero = '';
        for(var i = 0; i < digit; i++) zero += '0';
        return (zero + num).slice(-digit);
    }
  }
}
</script>

<style lang="scss">
@import '@/style';

html, body {
  overflow: hidden !important;
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
}

#app {
  background-color: #ddd;
  position: relative;
  overflow: hidden;

  display: flex;
  justify-content: center;
  flex-grow: 1;


}
  * {
    -khtml-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
user-select: none;
-webkit-touch-callout: none;
-webkit-user-select: none;
}

.rootpage {
  position: relative;
  background-color: white;
  max-width: 500px !important;
  align-self: center;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;

  @media only screen and (max-width: 768px) {
      box-shadow: unset;
      max-width: 100% !important;
  }

}

.v-main, .v-main__wrap {
  max-height: 100% !important;
}


.v-window, .v-window__container, .v-window__container--is-active, .v-window-item {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1 !important;
    height: 100% !important;
}

.v-system-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
}


.form-login {
    position: relative;
    padding: 0 3rem !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .btnsubmit {
        padding-left:  4rem !important;
        padding-right: 4rem !important;
        margin-top: 2rem;
    }

    .errormessage {
        height: 5rem;
        * {
            font-size: 0.9rem;
        }
        p {
            margin: 0;
            padding: 0;
        }
    }
}

.version {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  p {
    margin: 0.25rem;
    font-size: 0.75rem;
    color: #CCC;
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.5);
  }
}


</style>
