<template>
    <div class="videorecord">
        <video ref="camera" v-show="showWebCam" class="fullscreenvideo" muted webkit-playsinline="true" playsinline="true" ></video>
        <video ref="review" autoplay loop v-show="!showWebCam" class="fullscreenvideo" webkit-playsinline="true" playsinline="true" ></video>
        <div class="controls">
            <div v-if="state == RECORD_STATES.WAITING || state == RECORD_STATES.REC">
                <v-progress-circular
                    :rotate="-90"
                    :size="state == RECORD_STATES.WAITING ? 64 : 80"
                    :width="4"
                    :value="timePercentage"
                    color="white"
                    class="btnrecord"
                >
                    <button :class="{'circle':state == RECORD_STATES.WAITING}" @click="onClickRecord"></button>
                </v-progress-circular>
            </div>
            <div v-else-if="state == RECORD_STATES.REVIEW">
                <v-btn @click="discart" x-large><v-icon>mdi-close-circle</v-icon></v-btn>
                <v-btn @click="accept" x-large color="primary"><v-icon>mdi-thumb-up</v-icon></v-btn>
                <!-- <v-btn @click="submit" color="primary">Enviar</v-btn> -->
            </div>
            <div v-else>
                
            </div>
        </div>

        <div class="send-modal" v-if="state == RECORD_STATES.CONFIRM || state == RECORD_STATES.UPLOADING">
            <h3>Enviar vídeo</h3>
            <v-text-field class="mb-3 mt-4" label="Prédio - Apartamento" x-large autocomplete="off" v-model="description" :hint="error" persistent-hint  :disabled="state == RECORD_STATES.UPLOADING"></v-text-field>
            <v-btn color="primary" @click="submit" :loading="state == RECORD_STATES.UPLOADING" :disabled="state == RECORD_STATES.UPLOADING" >Enviar</v-btn>
            <p :class="{'cancel':true, 'canceldisabled':state == RECORD_STATES.UPLOADING}" @click="cancelUpload">Cancelar</p>
        </div>

        <div class="send-modal" v-if="state == RECORD_STATES.DONE">
            <h3>Vídeo Enviado!</h3>
            <v-icon class="mb-6 mt-4" color="green" style="font-size:80px !important">mdi-check-circle-outline</v-icon>
            <v-btn @click="discart" class="mb-2">Continuar</v-btn>
        </div>

        <!-- <div class="close"><v-btn icon @click="close"><v-icon color="white">mdi-close</v-icon></v-btn></div> -->
    </div>
</template>

<script>

import VideoRecorder from '../scripts/video-recorder.js'
//import ysFixWebmDuration from 'fix-webm-duration'
import { mapGetters } from 'vuex'

import { v4 as uuidv4 } from 'uuid';

export default {
    data: () => ({
        recorder: null,
        description: "",

        MAX_REC_TIME: 3 * 60 * 1000,
        elapsedTime: 0,
        startTime: null,
        updateInterval: null,

        reviewPlayer: null,
        error: null,

        RECORD_STATES: {
            WAITING: 0,
            REC: 1,
            REVIEW: 2,
            CONFIRM: 3,
            UPLOADING: 4,
            DONE: 5
        },

        state: 0
    }),

    mounted() {
        this.reviewPlayer = this.$refs.review;
        this.recorder = new VideoRecorder(this.$refs.camera)
        this.recorder.on('stop', (blob) => {
            console.log('stop')
            console.log('stop', blob)
            let video = this.reviewPlayer;
            video.src = this.recorder.savedURL()
        })

        this.updateInterval = setInterval(this.loop, 10)
    },

    beforeDestroy() {
        if (this.recorder != null) this.recorder.reset();
        clearInterval(this.updateInterval)
    },

    computed: {

        ...mapGetters(['user']),

        timePercentage() {
            return this.elapsedTime * 100 / this.MAX_REC_TIME
        },

        timePercentageInt() {
            return Math.round(this.timePercentage)
        },

        showWebCam() {
            return this.state == this.RECORD_STATES.WAITING || this.state == this.RECORD_STATES.REC
        },

        playing() {
            return this.state == this.RECORD_STATES.REVIEW
        }
    },

    methods: {


        discart(e) {
            console.log('discart')
            if (this.recorder != null) this.recorder.reset();
            this.reviewPlayer.pause();
            setTimeout(() => {
                this.state = this.RECORD_STATES.WAITING
            }, 100)
        },

        accept(e) {
            this.description = ""
            this.error = null
            this.state = this.RECORD_STATES.CONFIRM
            this.reviewPlayer.pause();
        },

        close() {
            console.log('close')
            console.log(this.user)
        },

        loop() {
            if (this.state == this.RECORD_STATES.REC) {
                this.elapsedTime = (+new Date()) - this.startTime;
                if (this.elapsedTime >= this.MAX_REC_TIME) {
                    this.stop()
                }
            }
        },

        play() {
            if (this.playing) {
                this.reviewPlayer.pause();
            }
            else {
                this.reviewPlayer.pause();
                this.reviewPlayer.currentTime = 0;
                this.reviewPlayer.play();
            }
        },

        async wait(ms) {
            return new Promise(res => setTimeout(res, ms))
        },

        async submit() {

            this.error = null
            let description = this.description.trim()
            if (description.length == 0) {
                console.log('invalid description')
                this.error = "Esse campo é obrigatório"
                return
            }

            this.state = this.RECORD_STATES.UPLOADING
            await this.wait(3000)
            this.reviewPlayer.pause();

            //TODO: Add error message here 
            //try {
            if (this.recorder.blob) {
                //const blob = await ysFixWebmDuration(this.recorder.chucks, this.elapsedTime, {logger: false})
                const blob = this.recorder.chucks
                const file = new File(blob, {type:'video/webm; codecs=vp9'})
                await this.$api.uploadVideo(file, description, this.user.id)
                //this.recorder.reset()
            }
            /*} catch {
                this.state = this.RECORD_STATES.REVIEW
                this.reviewPlayer.play();
                return
            }*/

            this.state = this.RECORD_STATES.DONE
        },

        onClickRecord() {
            if (this.state == this.RECORD_STATES.REC) this.stop()
            else this.start()
        },

        start() { 
            this.recorder.start()
            this.elapsedTime = 0
            this.startTime = +new Date()
            this.state = this.RECORD_STATES.REC
        },

        stop() {
            this.recorder.stop() 
            this.recording = false
            this.startTime = null
            this.elapsedTime = 0
            this.state = this.RECORD_STATES.REVIEW
        },

        cancelUpload() {
            console.log('cancel upload')
            this.state = this.RECORD_STATES.REVIEW
            this.reviewPlayer.play();
        },

        save() {
            console.log(this.recorder.blob)
            if (this.recorder.blob) {
                var a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                let url = this.recorder.savedURL()
                    a.href = url
                    a.download = 'teste';
                    a.click();
            }
        }

    }
}
</script>

<style lang="scss">

.v-progress-circular__overlay {
    transition: none !important;
}

</style>

<style lang="scss" scoped>

.send-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    min-width: 256px;
    min-height: 128px;
    border-radius: 1rem;
    padding: 1rem 2rem;
    text-align: center;

    display: flex;
    flex-direction: column;

    .cancel {
        font-size: 0.9rem;
        padding: 0.5rem;
        margin-top: 0.5rem;

        color: lighten(red, 30%);

        text-decoration-line: underline;
        text-underline-offset: 4px;
    }

    .canceldisabled {
        pointer-events: none;
        opacity: 0.5;
    }

}

.videorecord {


    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    overflow: hidden;

    .close {
        position: absolute;
        top: 1rem;
        right: 1rem;
    }

    button {
        background-color: gray;
    }

    button:hover {
        background-color: lightgray;
    }

    .fullscreenvideo {
        position: absolute;
        top:  50%;
        left: 50%;
        min-width: 100%;
        min-height: 100%;
        height: 100%;
        transform: translate(-50%, -50%);
        overflow: hidden;
    }

    .reviewvideo {
        position: absolute;
        top: 50%;
        left: 50%;
        width: calc(100% - 3rem);
        height: calc(100% - 3rem);
        transform: translate(-50%, -50%);
    }

    .controls {
        position: absolute;
        width: 100%;
        bottom: 2rem;
        left: 50%;
        transform: translate(-50%);
        display: flex;
        justify-content: center;

        .v-btn {
            margin: 0 0.4rem;
        }
    }

    .btnrecord {

        position: absolute;
        top: calc(50% - 2rem);
        left: 50%;
        transform: translate(-50%, -50%);


        p {
            color: black !important;
            z-index: 1;
            margin: 0;
            padding: 0;
            pointer-events: none;
        }

        button {
            background-color: white;
            width: 30px;
            height: 30px;
            border-radius: 4px;

            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            transition: all 0.5s;

            
        }

        .circle {
            background-color: red;
            width:  40px;
            height: 40px;
            border-radius: 50%;
        }
    }
}

</style>